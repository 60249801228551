export const initialState = {
    loader: false,
    login: {
        email: '',
        password: '',
    },
    loaderLong: false,
    modalData: {
        position:false
    },
    aboudData: '',
    blogs:[],
    faqs:[],
    trainings:[],
    trainers:[],
    whyUsData:[],
    trainingQuestions:[],
    studentReturns:[],
    contacts:[],
    trainingApply:[],
    questions:[],
    headerImages:[],
    trainerDocuments:[],
    trainingTrainer:[],
    eventsData:[],
    applyOptions:[]
}
