import React, { Component } from 'react'
import Button from '../components/Button'
import { connect } from 'react-redux';
import { getApplyOptions } from '../actions/MainAction';
import Card from '../components/Card';
import { changeStateValue } from '../redux/MainReducer';
export class ApplyOptionsPage extends Component {
    componentDidMount(){
        this.props.getApplyOptions();
    }
    editModalBtn=(data='')=>{
        this.props.changeStateValue({
            name:'modalData',
            value: {
              position: true,
              id: data?.id?data?.id:'',
              title: data?.title?data?.title:'',
              unrequire: ["unrequire", "funcParams", "id"],
              afterCallFunction: this.props.getApplyOptions,
              funcParams:[],
              url: data?.id? `apply-option/${data?.id}`: 'apply-option',
              _method:  data?.id? 'put':'post'
            }
          })
    }
    getAfterChange=()=>{
        this.props.getApplyOptions();
    }
  render() {
    const {applyOptions} = this.props;
    return (
        <div className='container  ml-auto mr-auto mt-10'>
        <div className='flex justify-between items-center'>
            <h2 className='text-xl text-blue-700 font-semibold'>Apply Option</h2>
            <Button title="Add Option" onClick={this.editModalBtn.bind(this)} className=" bg-blue-700"  />
        </div>
        <div className='grid grid-cols-4 gap-10 mt-10'>
           {
            applyOptions?.map((data, i)=>{
                return(
                    <Card
                        image={data?.image_full_url}
                        key={i}
                        title={data?.title}
                        editBtnData={data}
                        editModalBtn={this.editModalBtn}
                        url={`apply-option/${data?.id}`}
                        getAfterChange={this.getAfterChange}
                    />
                )
            })
           }
           
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    applyOptions: state.Data.applyOptions
});
const mapDispatchToProps = {getApplyOptions, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ApplyOptionsPage)

