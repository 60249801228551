import React, { Component } from 'react'
import Button from '../components/Button'
import Card from '../components/Card'
import { connect } from 'react-redux';
import { getAbout } from '../actions/MainAction';
import { changeStateValue } from '../redux/MainReducer';
export class MainPage extends Component {

  componentDidMount(){
    this.props.getAbout()
  }

  editModalBtn=(data)=> {
   
    this.props.changeStateValue({
      name:'modalData',
      value: {
        position: true,
        id: data?.id,
        title: data?.title,
        description: data?.description,
        facebook: data?.facebook,
        instagram: data?.instagram,
        linkedin: data?.linkedin?data?.linkedin:'',
        number: data?.number,
        youtube: data?.youtube,
        first_page_title: data?.first_page_title,
        unrequire: ["unrequire", "funcParams"],
        afterCallFunction: this.props.getAbout,
        funcParams:[],
        url: `about/${data?.id}`,
        _method: 'put'
      }
    })
  }
  render() {
    const {aboudData} = this.props;
    // console.log(aboudData)
    return (
      <div className='container  ml-auto mr-auto mt-10'>
        <div className='flex justify-between items-center'>
            <h2 className='text-xl text-blue-700 font-semibold'>About Page</h2>
           
        </div>
        <div className='grid grid-cols-4 gap-10 mt-10'>
            <Card
              title={aboudData?.title}
              deleteBtn={false}
              editBtnData={aboudData}
              editModalBtn={this.editModalBtn}
            />
           
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  aboudData: state.Data.aboudData
});
const mapDispatchToProps = {getAbout, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(MainPage)
