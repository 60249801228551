import React, { Component } from 'react'
import Button from '../components/Button'
import { connect } from 'react-redux';
import { getTrainerDocuments } from '../actions/MainAction';
import Card from '../components/Card';
import { changeStateValue } from '../redux/MainReducer';
import { useParams } from 'react-router-dom';
function withHooks(Component){
    return props => <Component {...props} params={useParams()} />
}
export class TrainerSingle extends Component {
    componentDidMount(){
        this.props.getTrainerDocuments({id:this.props.params.id});
    }
    editModalBtn=(data='')=>{
        this.props.changeStateValue({
            name:'modalData',
            value: {
              position: true,
              id: data?.id?data?.id:'',
              title: data?.title?data?.title:'',
              trainer_id: this.props.params.id,
              image_full_url: data?.image_full_url?data?.image_full_url:'',
              unrequire: ["unrequire", "funcParams", "id", "image_full_url", "syllabus_full_url"],
              afterCallFunction: this.props.getTrainerDocuments,
              funcParams:[{id:this.props.params.id}],
              url: data?.id? `trainer-documents/${data?.id}`: 'trainer-documents',
              _method:  data?.id? 'put':'post'
            }
          })
    }
    getAfterChange=()=>{
        this.props.getTrainerDocuments({id:this.props.params.id});
    }
  render() {
    const {trainerDocuments} = this.props;
    return (
        <div className='container  ml-auto mr-auto mt-10'>
        <div className='flex justify-between items-center'>
            <h2 className='text-xl text-blue-700 font-semibold'>Trainer documents Page</h2>
            <Button title="Add Document" onClick={this.editModalBtn.bind(this)} className=" bg-blue-700"  />
        </div>
        <div className='grid grid-cols-4 gap-10 mt-10'>
           {
            trainerDocuments?.map((data, i)=>{
                return(
                    <Card
                        image={data?.image_full_url}
                        key={i}
                        title={data?.title}
                        editBtnData={data}
                        editModalBtn={this.editModalBtn}
                        url={`trainer-documents/${data?.id}`}
                        getAfterChange={this.getAfterChange}
                    />
                )
            })
           }
           
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    trainerDocuments: state.Data.trainerDocuments
});
const mapDispatchToProps = {getTrainerDocuments, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(TrainerSingle))




