import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

export class Header extends Component {
  render() {
    return (
      <div className='bg-white h-20 shadow shadow-lg rounded-b-2xl flex justify-center items-center'>
        <div className='container flex justify-center items-center'>
            <nav>
                <ul className='flex gap-4'>
                    <li className='text-lg'><NavLink to="/">Haqqimizda</NavLink></li>
                    <li className='text-lg'><NavLink to="/blog">Blog</NavLink></li>
                    <li className='text-lg'><NavLink to="/events">Events</NavLink></li>
                    <li className='text-lg'><NavLink to="/faq">FAQs</NavLink></li>
                    <li className='text-lg'><NavLink to="/training">Telimler</NavLink></li>
                    <li className='text-lg'><NavLink to="/trainer">Muellimler</NavLink></li>
                    <li className='text-lg'><NavLink to="/why-us">Niye Biz</NavLink></li>
                    <li className='text-lg'><NavLink to="/contacts">Contacts</NavLink></li>
                    <li className='text-lg'><NavLink to="/training-apply">Telim muracieti</NavLink></li>
                    <li className='text-lg'><NavLink to="/questions">Suallar</NavLink></li>
                    <li className='text-lg'><NavLink to="/header-image">Shekiller</NavLink></li>
                    <li className='text-lg'><NavLink to="/apply-options">Apply Options</NavLink></li>
                    
                </ul>
            </nav>
        </div>
      </div>
    )
  }
}

export default Header
