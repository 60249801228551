import React, { Component } from 'react'
import Button from '../components/Button'
import { connect } from 'react-redux';
import { getTrainers } from '../actions/MainAction';
import Card from '../components/Card';
import { changeStateValue } from '../redux/MainReducer';
export class TrainerPage extends Component {
    componentDidMount(){
        this.props.getTrainers();
    }
    editModalBtn=(data='')=>{
        this.props.changeStateValue({
            name:'modalData',
            value: {
              position: true,
              id: data?.id?data?.id:'',
              name: data?.name?data?.name:'',
              description: data?.description?data?.description:'',
              image_full_url: data?.image_full_url?data?.image_full_url:'',
              position_status: data?.position?data?.position:'',
              sub_position: data?.sub_position?data?.sub_position:'',
           
              unrequire: ["unrequire", "funcParams", "id", "image_full_url", "sub_position", "description"],
              afterCallFunction: this.props.getTrainers,
              funcParams:[],
              url: data?.id? `trainer/${data?.id}`: 'trainer',
              _method:  data?.id? 'put':'post'
            }
          })
    }
    getAfterChange=()=>{
        this.props.getTrainers();
    }
  render() {
    const {trainers} = this.props;
    return (
        <div className='container  ml-auto mr-auto mt-10'>
        <div className='flex justify-between items-center'>
            <h2 className='text-xl text-blue-700 font-semibold'>Trainer Page</h2>
            <Button title="Add Trainer" onClick={this.editModalBtn.bind(this)} className=" bg-blue-700"  />
        </div>
        <div className='grid grid-cols-4 gap-10 mt-10'>
           {
            trainers?.map((data, i)=>{
                return(
                    <Card
                        image={data?.image_full_url}
                        key={i}
                        title={data?.name}
                        editBtnData={data}
                        editModalBtn={this.editModalBtn}
                        url={`trainer/${data?.id}`}
                        linkPosition={true}
                        linkUrl={`/trainer/${data?.id}`}
                        getAfterChange={this.getAfterChange}
                    />
                )
            })
           }
           
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    trainers: state.Data.trainers
});
const mapDispatchToProps = {getTrainers, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(TrainerPage)



