import React, { Component } from 'react'
import Button from '../components/Button'
import QuestionsPage from './QuestionsPage';
import StudentReturnPage from './StudentReturnPage';
import { useParams } from 'react-router-dom';
import TrainingTrainer from './TrainingTrainer';
function withHooks(Component){
    return props => <Component {...props} params={useParams()} />
}
export class TrainingInfoPage extends Component {
    state={
        page: 'question'
    }
  render() {
    const {page} = this.state;
    return (
      <div className='container  ml-auto mr-auto mt-10'>
        <div className='flex gap-3'>
            <Button title="Suallar" onClick={()=>this.setState({ page: 'question'})} className={page == 'question'?"bg-blue-500": "bg-gray-400 border " }/>
            <Button title="Telebe geridonusu" onClick={()=>this.setState({ page: 'student'})} className={page == 'student'?"bg-blue-500": "bg-gray-400 border " } />
            <Button title="Telimchi" onClick={()=>this.setState({ page: 'trainer'})} className={page == 'trainer'?"bg-blue-500": "bg-gray-400 border " } />
        </div>
        {
            page == "question"?
            <QuestionsPage trainingId={this.props.params.id} />:
            page == "student"?
            <StudentReturnPage trainingId={this.props.params.id} />:
            <TrainingTrainer trainingId={this.props.params.id} />
        }
      </div>
    )
  }
}

export default withHooks(TrainingInfoPage)
