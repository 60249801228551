import React, { Component } from 'react'
import Button from '../components/Button'
import { connect } from 'react-redux';
import { getWhyUs } from '../actions/MainAction';
import Card from '../components/Card';
import { changeStateValue } from '../redux/MainReducer';
export class WhyUsPage extends Component {
    componentDidMount(){
        this.props.getWhyUs();
    }
    editModalBtn=(data='')=>{
        this.props.changeStateValue({
            name:'modalData',
            value: {
              position: true,
              id: data?.id?data?.id:'',
              title: data?.title?data?.title:'',
              description: data?.description?data?.description:'',
              image_full_url: data?.image_full_url?data?.image_full_url:'',
             
              unrequire: ["unrequire", "funcParams", "id", "image_full_url", "syllabus_full_url"],
              afterCallFunction: this.props.getWhyUs,
              funcParams:[],
              url: data?.id? `why-us/${data?.id}`: 'why-us',
              _method:  data?.id? 'put':'post'
            }
          })
    }
    getAfterChange=()=>{
        this.props.getWhyUs();
    }
  render() {
    const {whyUsData} = this.props;
    return (
        <div className='container  ml-auto mr-auto mt-10'>
        <div className='flex justify-between items-center'>
            <h2 className='text-xl text-blue-700 font-semibold'>Why Us Page</h2>
            <Button title="Add Why Us" onClick={this.editModalBtn.bind(this)} className=" bg-blue-700"  />
        </div>
        <div className='grid grid-cols-4 gap-10 mt-10'>
           {
            whyUsData?.map((data, i)=>{
                return(
                    <Card
                        image={data?.image_full_url}
                        key={i}
                        title={data?.title}
                        editBtnData={data}
                        editModalBtn={this.editModalBtn}
                        url={`why-us/${data?.id}`}
                        getAfterChange={this.getAfterChange}
                    />
                )
            })
           }
           
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    whyUsData: state.Data.whyUsData
});
const mapDispatchToProps = {getWhyUs, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(WhyUsPage)



