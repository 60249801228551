import React, { Component } from 'react'

export class Button extends Component {
  render() {
    const {title, onClick, className, disabled} = this.props;
    return (
      <button disabled={disabled} className={"p-2 rounded-xl text-white text-sm cursor-pointer"+ " "+className} onClick={()=>onClick()}>{title}</button>
    )
  }
}

export default Button
