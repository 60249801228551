import axios from "axios";
import { MAIN_API } from "../MAIN_API";
import {changeStateValue} from '../redux/MainReducer';

export const loginAction = (data)=> async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.post(`${MAIN_API}/login`, data)
    .then(resp=>{
        console.log(resp.data)
        localStorage.setItem("nicodeToken", resp.data?.token);
        dispatch(changeStateValue({name: 'loader', value:false}))

        return "success";
    }).catch(err=>{
        console.log(err.response);
        dispatch(changeStateValue({name: 'loader', value:false}))

        return "error";
    })
}

export const getAdminDetails=()=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/user/1`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name: 'loader', value:false}))

       return 'success'
    }).catch(err=>{
        dispatch(changeStateValue({name: 'loader', value:false}))

        console.log(err.response)
        return 'error'
    })
}
export const getAbout=()=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/about`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'aboudData',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getBlogs=()=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/blog`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'blogs',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getFaqs=()=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/faq`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'faqs',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getTrainings=()=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/training`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'trainings',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getTrainers=()=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/trainer`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'trainers',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getEvents=()=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/events`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'eventsData',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getWhyUs=()=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/why-us`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'whyUsData',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getTrainingQuestons=(id)=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/training-questions?training_id=${id}`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'trainingQuestions',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getTrainingTrainerCheck=(id)=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/training-tr-check?id=${id}`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'trainingTrainer',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getStudentdReturns=(data)=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/student-return`, {
        params: {...data},
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'studentReturns',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getTrainerDocuments=(data)=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/trainer-documents`, {
        params: {...data},
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'trainerDocuments',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getContacts=()=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/contact`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'contacts',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getApplyOptions=()=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/apply-option`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'applyOptions',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}


export const getTrainingApply=()=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/traning-apply`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'trainingApply',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getHeaderImages=()=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/header-images`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'headerImages',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getQuestions=()=>async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.get(`${MAIN_API}/questions`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'questions',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
    })
}

export const insertNewData = (url, data) => async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.post(`${MAIN_API}/${url}`, data, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        console.log(resp.data);
        dispatch(changeStateValue({name: 'loader', value:false}))

        return 'success';
    }).catch(err=>{
        console.log(err.response);
        dispatch(changeStateValue({name: 'loader', value:false}))

        return 'error';
    })
}
export const deleteData = (url) => async dispatch => {
    dispatch(changeStateValue({name: 'loader', value:true}))
    return await axios.delete(`${MAIN_API}/${url}`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("nicodeToken")}`
        }
    }).then(resp=>{
        console.log(resp.data);
        dispatch(changeStateValue({name: 'loader', value:false}))

        return 'success';
    }).catch(err=>{
        console.log(err.response);
        dispatch(changeStateValue({name: 'loader', value:false}))
        return 'error';
    })
}