import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getTrainingTrainerCheck, getTrainers, insertNewData } from '../actions/MainAction';
import Card from '../components/Card';
import Button from '../components/Button';
import Swal from 'sweetalert2';
export class TrainingTrainer extends Component {
    state={
        btnVisible:false,
        trainerId: ''
    }
    componentDidMount(){
        this.props.getTrainingTrainerCheck(this.props.trainingId);
        this.props.getTrainers();
    }
    getAfterChange=()=>{
        this.props.getTrainingTrainerCheck(this.props.trainingId)
    }
    setTrainer(trainerId){
        // console.log('ss', trainerId)
        if(trainerId != ''){
            let formData = new FormData();
            formData.append("training_id", this.props.trainingId)
            formData.append("trainer_id", trainerId);
            this.props.insertNewData("training-trainer", formData)
            .then(resp=>{
                this.props.getTrainingTrainerCheck(this.props.trainingId);
                if(resp == "success"){
                  
                    Swal.fire(
                        'Success!',
                        'Changes saved succesfully',
                        'success'
                    )
                }else{
                    Swal.fire(
                        'Error!',
                        'Something went wronge',
                        'error'
                    )
                }
            })
        }
    }

    editStatusBtn=(data)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it!'
          }).then((result) => {
            if (result.isConfirmed) {

                let formData = new FormData();
                formData.append("_method", "put");
                formData.append("show_about", data?.show_about==1?"0":"1")
                this.props.insertNewData(`training-trainer/${data?.id}`, formData)
                .then(resp=>{
                    this.props.getTrainingTrainerCheck(this.props.trainingId);
                    if(resp == "success"){
                        Swal.fire(
                          'changed!',
                          'Your file has been changed.',
                          'success'
                        )
                      }else{
                        Swal.fire(
                          'Error!',
                          'Something went wronge',
                          'error'
                        )
                      }
                })
            }
          })
    }
  render() {
    const {trainingTrainer, trainers} = this.props;
    const {btnVisible, trainerId} = this.state;
    return (
        <div className='container  ml-auto mr-auto mt-10'>
        <div className='flex justify-between items-center'>
            <h2 className='text-xl text-blue-700 font-semibold'>Telimci</h2>
        </div>
        <div>
             <div className='flex gap-3'>
                    <select onChange={e=>this.setState({trainerId:e.target.value})}  className='w-1/5 '>
                        <option vaslue="">Telimchi sech</option>
                        {
                            trainers?.map((data, i)=>{
                                return(
                                    <option key={i} value={data?.id}>{data?.name}</option>
                                )
                            })
                        }
                    </select>
                    <Button disabled={btnVisible} title="Teyin et" onClick={this.setTrainer.bind(this, trainerId)} className=" bg-blue-700" />
                </div>
          
               
                <div className='grid grid-cols-4 gap-10 mt-10'>
                {
                trainingTrainer.map((data, i) => {
                    return(
                    <Card 
                        key={i}
                        image={data?.user?.image_full_url}
                        name={data?.user?.name}
                        editBtnData={data}
                        editAccess={false}
                        url={`training-trainer/${data?.id}`}
                        getAfterChange={this.getAfterChange}
                        status={data?.show_about}
                        statusView={true}
                        editStatusBtn={this.editStatusBtn.bind(this)}
                    />
                    )
                })
            }
                </div>
                
            
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    trainingTrainer: state.Data.trainingTrainer,
    trainers: state.Data.trainers,
});
const mapDispatchToProps = {getTrainingTrainerCheck, getTrainers, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(TrainingTrainer)
