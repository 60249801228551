import React, { Component } from 'react'
import Input from './Input'
import Button from './Button'
import Editor from './Editor'
import { connect } from 'react-redux'
import { changeStateValue } from '../redux/MainReducer';
import { insertNewData } from '../actions/MainAction'
import Swal from 'sweetalert2'
export class Modal extends Component {
    state={
        base64Img: this.props.modalData?.image_full_url,
        syllabus: this.props.modalData?.syllabus_full_url,
        btnDisable:false
    }
    closeModal=()=>{
        this.props.changeStateValue({
            name: 'modalData',
            value: {position:false}
        })
    }

    changeInpValue(e){
        this.props.changeStateValue({
            name: e.target.name,
            value:e.target.value
        })
    }

    saveModalData=(data) => {
        
        let permission = true;
        for(let property in data){
            const check = data.unrequire.includes(property);
            if(!check){
                if(data[property] == ''){
                    permission = false;
                }
            }
            
        }

        if(permission){
            this.setState({btnDisable:true})
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property])
            }
            formData.append("position", data?.position_status)
            let url = data?.url;
            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.setState({btnDisable:false})
                if(resp == "success"){
                    Swal.fire(
                        'Success!',
                        'Changes saved succesfully',
                        'success'
                    )
                    this.closeModal();
                    data.afterCallFunction(...data?.funcParams)
                }else{
                    Swal.fire(
                        'Error!',
                        'Something went wronge',
                        'error'
                    )
                }
                
            })
        }
    }

    getImg(file){
        return new Promise((res, rej)=>{
            let reader = new FileReader();
            reader.onload=()=>{
                res(reader.result)
            }

            reader.readAsDataURL(file)
        })
    }

    getInpValue(e){
        this.getImg(e.target.files[0])
        .then(resp=>{
            this.setState({base64Img: resp});
            this.props.changeStateValue({
                name: 'modalData.image',
                value: e.target.files[0]
            })
        })
    }

    getSyllabus(e){
        this.props.changeStateValue({
            name: 'modalData.syllabus',
            value: e.target.files[0]
        });
        this.setState({syllabus: e.target.files[0]})
    }

    deleteFile=()=>{
        this.props.changeStateValue({
            name:"modalData.syllabus_full_url",
            value:''
        })
        this.props.changeStateValue({
            name:"modalData.syllabus",
            value:''
        });
        this.setState({syllabus:''})
}
  render() {
    const {modalData} = this.props;
    const {base64Img, syllabus, btnDisable} = this.state;
    // console.log(syllabus)
    return (
      <div  style={{ backgroundColor: 'rgba(0,0,0,0.2)' }} className='fixed flex items-center justify-center w-full h-screen top-0 left-0'>
            <div style={{ maxHeight:'70vh', overflow:'auto' }} className='w-1/2 p-3 bg-white shadow shadow-lg rounded-lg'>
                <div className='flex items-center justify-between'>
                    <h3>Add Data</h3>
                </div>
                <div className='flex gap-5'>
                    {
                        modalData?.image_full_url || modalData?.image_full_url == ""?
                        <div>
                            <label  htmlFor='imgInp' className='relative inline-flex justify-center-items-center rounded-xl w-64 h-64 '>
                                <img src={base64Img} className='w-full h-full' />
                                <div style={{ backgroundColor: 'rgba(0,0,0,0.3)' }} className='absolute left-0 top-0 w-full h-full rounded-xl flex justify-center-items-center'>
                                    <span className='w-full flex text-center items-center justify-center cursor-pointer'>Upload image</span>
                                </div>
                            </label>
                            <input accept='image/*' type='file' id='imgInp' style={{ display: 'none' }} onChange={this.getInpValue.bind(this)} />
                        </div>:null
                    }
                    {
                        modalData?.syllabus_full_url || modalData?.syllabus_full_url == ""?
                        <div>
                            <label  htmlFor='pdfUpload' className='relative inline-flex justify-center-items-center rounded-xl w-64 h-64 '>
                                {
                                    syllabus == ''?
                                    <img src={''} className='w-full h-full' />:
                                    <img src={'https://play-lh.googleusercontent.com/BkRfMfIRPR9hUnmIYGDgHHKjow-g18-ouP6B2ko__VnyUHSi1spcc78UtZ4sVUtBH4g'} className='w-full h-full' />

                                }
                                
                                <div style={{ backgroundColor: 'rgba(0,0,0,0.3)' }} className='absolute left-0 top-0 w-full h-full rounded-xl flex justify-center-items-center'>
                                    <span className='w-full flex text-center items-center justify-center cursor-pointer'>Upload Syllabus</span>
                                </div>
                            </label>
                            <input accept='application/pdf' type='file' id='pdfUpload' style={{ display: 'none' }} onChange={this.getSyllabus.bind(this)} />
                            <button onClick={this.deleteFile.bind(this)} className='bg-red-600 text-[14px] p-[6px] rounded-[5px] text-white'>Sil</button>
                        </div>:null
                    }
                </div>
                
                <div className='grid grid-cols-2 gap-3 mt-3'>
                    {!modalData?.nameEditor?
                        modalData?.name || modalData?.name == ''?
                        <Input
                            name="modalData.name"
                            type="text" 
                            title="Name"
                            value={modalData?.name}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null:null
                    }
                    {
                        modalData?.position_status || modalData?.position_status == ''?
                        <Input
                            name="modalData.position_status"
                            type="text" 
                            title="Position"
                            value={modalData?.position_status}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.title || modalData?.title == ''?
                        <Input
                            name="modalData.title"
                            type="text" 
                            title="Title"
                            value={modalData?.title}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.facebook || modalData?.facebook == ''?
                        <Input
                            name="modalData.facebook"
                            type="text" 
                            title="Facebook"
                            value={modalData?.facebook}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.raiting || modalData?.raiting == ''?
                        <Input
                            name="modalData.raiting"
                            type="number" 
                            title="Raiting (1-5)"
                            value={modalData?.raiting}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.question || modalData?.question == ''?
                        <Input
                            name="modalData.question"
                            type="text" 
                            title="Question"
                            value={modalData?.question}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                   
                    {
                        modalData?.instagram || modalData?.instagram == ''?
                        <Input
                            name="modalData.instagram"
                            type="text" 
                            title="Instagram"
                            value={modalData?.instagram}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.linkedin || modalData?.linkedin == ''?
                        <Input
                            name="modalData.linkedin"
                            type="text" 
                            title="Linkedin"
                            value={modalData?.linkedin}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.number || modalData?.number == ''?
                        <Input
                            name="modalData.number"
                            type="number" 
                            title="Number"
                            value={modalData?.number}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.youtube || modalData?.youtube == ''?
                        <Input
                            name="modalData.youtube"
                            type="text" 
                            title="Youtube"
                            value={modalData?.youtube}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.first_page_title || modalData?.first_page_title == ''?
                        <Input
                            name="modalData.first_page_title"
                            type="text" 
                            title="First Page Title"
                            value={modalData?.first_page_title}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.price || modalData?.price == ''?
                        <Input
                            name="modalData.price"
                            type="text" 
                            title="Price"
                            value={modalData?.price}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.path || modalData?.path == ''?
                        <Input
                            name="modalData.path"
                            type="text" 
                            title="Path"
                            value={modalData?.path}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.sub_position || modalData?.sub_position == ''?
                        <Input
                            name="modalData.sub_position"
                            type="text" 
                            title="Sub Position"
                            value={modalData?.sub_position}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.duration || modalData?.duration == ''?
                        <Input
                            name="modalData.duration"
                            type="text" 
                            title="Duration"
                            value={modalData?.duration}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.sub_title || modalData?.sub_title == ''?
                        <Input
                            name="modalData.sub_title"
                            type="text" 
                            title="Sub Title"
                            value={modalData?.sub_title}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.student_price || modalData?.student_price == ''?
                        <Input
                            name="modalData.student_price"
                            type="text" 
                            title="Student Price"
                            value={modalData?.student_price}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.date || modalData?.date == ''?
                        <Input
                            name="modalData.date"
                            type="date" 
                            title="Date"
                            value={modalData?.date}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.registration_url || modalData?.registration_url == ''?
                        <Input
                            name="modalData.registration_url"
                            type="text" 
                            title="Registration Url"
                            value={modalData?.registration_url}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.location || modalData?.location == ''?
                        <Input
                            name="modalData.location"
                            type="text" 
                            title="Address"
                            value={modalData?.location}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    {
                        modalData?.location_url || modalData?.location_url == ''?
                        <Input
                            name="modalData.location_url"
                            type="text" 
                            title="Location Url"
                            value={modalData?.location_url}
                            labelClass="text-black text-sm"
                            className="bg-gray-100 p-2 rounded-xl text-sm"
                        />:null
                    }
                    
                   
                </div>
                {
                    modalData?.nameEditor?
                        modalData?.name || modalData?.name == ''?
                       
                        <Editor 
                            name="modalData.name"
                            title="Name"
                            value={modalData?.name}
                        />
                        :null:null
                    }
                {
                        modalData?.answer || modalData?.answer == ''?
                        // <Input
                        //     name="modalData.answer"
                        //     type="text" 
                        //     title="Answer"
                        //     value={modalData?.answer}
                        //     labelClass="text-black text-sm"
                        //     className="bg-gray-100 p-2 rounded-xl text-sm"
                        // />
                        <Editor 
                            name="modalData.answer"
                            title="Answer"
                            value={modalData?.answer}
                        />
                        :null
                    }
               {
                modalData?.description || modalData?.description == ''?
                // <div className='flex flex-col mt-3'>
                //     <label className='text-sm'>Description</label>
                //     <textarea 
                //         className='border rounded-xl p-2 text-sm h-44'
                //         name={"modalData.description"}
                //         value={modalData?.description}
                //         onChange={this.changeInpValue.bind(this)}
                //     ></textarea>
                // </div>
                <Editor 
                    name="modalData.description"
                    title="Description"
                    value={modalData?.description}
                />
                :null
               }
               {
                modalData?.training_progress || modalData?.training_progress == ''?
                // <div className='flex flex-col mt-3'>
                //     <label className='text-sm'>Training Progress</label>
                //     <textarea 
                //         className='border rounded-xl p-2 text-sm h-44'
                //         name={"modalData.training_progress"}
                //         value={modalData?.training_progress}
                //         onChange={this.changeInpValue.bind(this)}
                //     ></textarea>
                // </div>
                <Editor 
                    name="modalData.training_progress"
                    title="Training Progress"
                    value={modalData?.training_progress}
                />
                :null
               }
               {
                modalData?.text || modalData?.text == ''?
                <div className='flex flex-col mt-3'>
                    <label className='text-sm'>Description</label>
                    <textarea 
                        className='border rounded-xl p-2 text-sm h-44'
                        name={"modalData.text"}
                        value={modalData?.text}
                        onChange={this.changeInpValue.bind(this)}
                    ></textarea>
                </div>
                :null
               }
                {/* <Editor onChange={(value, name)=>console.log(value, name)} /> */}
                <div className='mt-4 flex justify-between items-center'>
                    <Button title="Close" disabled={btnDisable} onClick={()=>this.closeModal()} className=" bg-red-700" />
                    <Button title="Edit" disabled={btnDisable} onClick={()=>this.saveModalData(modalData)} className=" bg-blue-700" />
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    modalData: state.Data.modalData
});
const mapDispatchToProps = {changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(Modal)
