import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getContacts } from '../actions/MainAction';
import BigCard from '../components/BigCard';
export class ContactsPage extends Component {

    componentDidMount(){
        this.props.getContacts()
    }
  render() {
    const {contacts} = this.props;
    return (
      <div className='container ml-auto mr-auto'>
        {
            contacts.map((data, i)=>{
                return(
                   <BigCard 
                    key={i}
                    name={data?.first_name}
                    lastName={data?.last_name}
                    email={data?.email}
                    topic={data?.topic}
                    desc={data?.text}
                   />
                )
            })
        }
      </div>
    )
  }
}
const mapStateToProps  = (state) =>({
    contacts: state.Data.contacts
});
const mapDispatchToProps = {getContacts}
export default connect(mapStateToProps, mapDispatchToProps)(ContactsPage)
