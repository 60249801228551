import React, { Component } from 'react'

export class LoadingPage extends Component {
  render() {
    return (
      <div style={{ backgroundColor: 'rgba(0,0,0,0.5)' }} className='fixed top-0 left-0 w-full h-screen flex items-center justify-center'>
        <h1 className='text-white text-2xl font-bold'>Loadind...</h1>
      </div>
    )
  }
}

export default LoadingPage