import React, { Component } from 'react'
import Button from '../components/Button'
import { connect } from 'react-redux';
import { getHeaderImages } from '../actions/MainAction';
import Card from '../components/Card';
import { changeStateValue } from '../redux/MainReducer';
export class HeaderImagesPage extends Component {
    componentDidMount(){
        this.props.getHeaderImages();
    }
    editModalBtn=(data='')=>{
        this.props.changeStateValue({
            name:'modalData',
            value: {
              position: true,
              id: data?.id?data?.id:'',
              title: data?.title?data?.title:'',
              image_full_url: data?.image_full_url?data?.image_full_url:'',
              unrequire: ["unrequire", "funcParams", "id", "image_full_url"],
              afterCallFunction: this.props.getHeaderImages,
              funcParams:[],
              url: data?.id? `header-images/${data?.id}`: 'header-images',
              _method:  data?.id? 'put':'post'
            }
          })
    }
    getAfterChange=()=>{
        this.props.getHeaderImages();
    }
  render() {
    const {headerImages} = this.props;
    return (
        <div className='container  ml-auto mr-auto mt-10'>
        <div className='flex justify-between items-center'>
            <h2 className='text-xl text-blue-700 font-semibold'>Header Image Page</h2>
            <Button title="Add Image" onClick={this.editModalBtn.bind(this)} className=" bg-blue-700"  />
        </div>
        <div className='grid grid-cols-4 gap-10 mt-10'>
           {
            headerImages?.map((data, i)=>{
                return(
                    <Card
                        image={data?.image_full_url}
                        title={data?.title}
                        key={i}
                        editBtnData={data}
                        editModalBtn={this.editModalBtn}
                        url={`header-images/${data?.id}`}
                        getAfterChange={this.getAfterChange}
                    />
                )
            })
           }
           
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    headerImages: state.Data.headerImages
});
const mapDispatchToProps = {getHeaderImages, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderImagesPage)