
import React, { Component, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import MainPage from './pages/MainPage'
import Header from './components/Header'
import Modal from './components/Modal'
import { connect, useDispatch, useSelector } from 'react-redux'
import BlogPage from './pages/BlogPage'
import FaqPage from './pages/FaqPage'
import TrainingPage from './pages/TrainingPage'
import TrainerPage from './pages/TrainerPage'
import WhyUsPage from './pages/WhyUsPage'
import TrainingInfoPage from './pages/TrainingInfoPage'
import ContactsPage from './pages/ContactsPage'
import TrainingApply from './pages/TrainingApply'
import QuestionsPublicPage from './pages/QuestionsPublicPage'
import HeaderImagesPage from './pages/HeaderImagesPage'
import TrainerSingle from './pages/TrainerSingle'
import { getAdminDetails } from './actions/MainAction'
import LoadingPage from './components/LoadingPage'
import DataEventsPage from './pages/DataEventsPage'
import ApplyOptionsPage from './pages/ApplyOptionsPage'

function App() {

  const modalData = useSelector(state=> state.Data.modalData);
  const loader = useSelector(state=> state.Data.loader);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(()=>{
    if(localStorage.getItem("nicodeToken")){
      dispatch(getAdminDetails())
      .then(resp=>{
        if(resp == "error"){

          localStorage.removeItem("nicodeToken")
          navigate("/login")
        }
      })
    }else{
      navigate("/login")
    }
  }, [])
  return (
    <div>
    <Header />
    <Routes>
      <Route path='/login' element={<LoginPage /> } />
      <Route path='/' element={<MainPage /> } />
      <Route path='/blog' element={<BlogPage /> } />
      <Route path='/faq' element={<FaqPage /> } />
      <Route path='/training' element={<TrainingPage /> } />
      <Route path='/trainer' element={<TrainerPage /> } />
      <Route path='/why-us' element={<WhyUsPage /> } />
      <Route path='/training/:id' element={<TrainingInfoPage /> } />
      <Route path='/trainer/:id' element={<TrainerSingle /> } />
      <Route path='/contacts' element={<ContactsPage /> } />
      <Route path='/training-apply' element={<TrainingApply /> } />
      <Route path='/questions' element={<QuestionsPublicPage /> } />
      <Route path='/header-image' element={<HeaderImagesPage /> } />
      <Route path='/events' element={<DataEventsPage /> } />
      <Route path='/apply-options' element={<ApplyOptionsPage /> } />
    </Routes>
    {
      modalData?.position?
      <Modal />:null
    }
    {
      loader?<LoadingPage />:null
    }
    
  </div>
  )
}

export default App

