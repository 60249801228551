import React, { Component } from 'react'
import Button from '../components/Button'
import { connect } from 'react-redux';
import { getEvents } from '../actions/MainAction';
import Card from '../components/Card';
import { changeStateValue } from '../redux/MainReducer';
export class DataEventsPage extends Component {
    componentDidMount(){
        this.props.getEvents();
    }
    editModalBtn=(data='')=>{
        this.props.changeStateValue({
            name:'modalData',
            value: {
              position: true,
              id: data?.id?data?.id:'',
              name: data?.name?data?.name:'',
              description: data?.description?data?.description:'',
              image_full_url: data?.image_full_url?data?.image_full_url:'',
              date: data?.date?data?.date:'',
              duration: data?.duration?data?.duration:'',
              price: data?.price?data?.price:'',
              registration_url: data?.registration_url?data?.registration_url:'',
              location_url: data?.location_url?data?.location_url:'',
              location: data?.location?data?.location:'',
              unrequire: ["unrequire", "funcParams", "id", "image_full_url", "location_url"],
              afterCallFunction: this.props.getEvents,
              nameEditor: true,
              funcParams:[],
              url: data?.id? `events/${data?.id}`: 'events',
              _method:  data?.id? 'put':'post'
            }
          })
    }
    getAfterChange=()=>{
        this.props.getEvents();
    }
  render() {
    const {eventsData} = this.props;
    return (
        <div className='container  ml-auto mr-auto mt-10'>
        <div className='flex justify-between items-center'>
            <h2 className='text-xl text-blue-700 font-semibold'>Events Page</h2>
            <Button title="Add Events" onClick={this.editModalBtn.bind(this)} className=" bg-blue-700"  />
        </div>
        <div className='grid grid-cols-4 gap-10 mt-10'>
           {
            eventsData?.map((data, i)=>{
                return(
                    <Card
                        image={data?.image_full_url}
                        key={i}
                        titleHTML={data?.name}
                        editBtnData={data}
                        editModalBtn={this.editModalBtn}
                        url={`events/${data?.id}`}
                       
                        getAfterChange={this.getAfterChange}
                    />
                )
            })
           }
           
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    eventsData: state.Data.eventsData
});
const mapDispatchToProps = {getEvents, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(DataEventsPage)





