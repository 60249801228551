import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getQuestions } from '../actions/MainAction';
import BigCard from '../components/BigCard';
export class QuestionsPublicPage extends Component {

    componentDidMount(){
        this.props.getQuestions()
    }
  render() {
    const {questions} = this.props;
    console.log(questions)
    return (
      <div className='container ml-auto mr-auto'>
        {
            questions.map((data, i)=>{
                return(
                   <BigCard 
                    key={i}
                    name={data?.first_name}
                    lastName={data?.last_name}
                    email={data?.email}
                    phone_number={data?.phone_number}
                    desc={data?.text}
                    training={data?.training_id}
                   />
                )
            })
        }
      </div>
    )
  }
}
const mapStateToProps  = (state) =>({
    questions: state.Data.questions
});
const mapDispatchToProps = {getQuestions}
export default connect(mapStateToProps, mapDispatchToProps)(QuestionsPublicPage)




