import React, { Component } from 'react'

export class BigCard extends Component {
  render() {
    const {name, lastName, topic="", email, desc, training="", phone_number="", option=""} = this.props;
    return (
      <div className='border mt-5 rounded-xl p-4 bg-white'>
       <table className="table-auto border-collapse border border-slate-400 w-full">
        <thead className='border-collapse'>
            <tr>
                <th className='border border-slate-300 '>Ad </th>
                <th className='border border-slate-300 '>Soyad</th>
                <th className='border border-slate-300 '>email</th>
                {
                    phone_number != ''?
                    <th className='border border-slate-300 '>Nomre</th>:null
                }
                {
                    topic != ''?
                    <th className='border border-slate-300 '>Topic</th>:null
                }
                
                {
                    training != ''?
                    <th className='border border-slate-300 '>Telim</th>:null
                }
                
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className='border border-slate-300 text-center'>{name}</td>
                <td className='border border-slate-300 text-center'>{lastName}</td>
                <td className='border border-slate-300 text-center'>{email}</td>
                {
                     phone_number != ''?
                     <td className='border border-slate-300 text-center'>{phone_number}</td>:null
                }
                {
                    topic != ''?
                    <td className='border border-slate-300 text-center'>{topic}</td>:null
                }
                
                {
                     training != ''?
                     <td className='border border-slate-300 text-center'>{training}</td>:null
                }
                
            </tr>
        </tbody>
        <p className='p-2 text-sm'>{desc}</p>
        {
            option && option !=""?
            <pre className='p-2 text-sm'>{option}</pre>:null
        }
        
        </table>
      </div>
    )
  }
}

export default BigCard
