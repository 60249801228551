import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
export class Input extends Component {

  changeInpValue(e){
    this.props.changeStateValue({
      name: e.target.name,
      value:e.target.value
    })
  }
  render() {
    const {name, type, title, value, labelClass='', className=""} = this.props
    return (
      <div className='flex flex-col'>
        <label className={labelClass} htmlFor={title}>{title}</label>
        <input onChange={this.changeInpValue.bind(this)} className={className} id={title}  placeholder={title} type={type} name={name} value={value} />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Input)
