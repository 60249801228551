import React, { Component } from 'react'
import Input from '../components/Input'
import Button from '../components/Button'
import { connect } from 'react-redux';
import { loginAction } from '../actions/MainAction';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function withHooks(Component){
  return props => <Component {...props} navigate={useNavigate()} />
}
export class LoginPage extends Component {
  state={
    btnVisible:false
  }
  loginBtn(data){
    if(data?.email.trim() != '' && data?.password?.trim() != ''){
      this.setState({btnVisible:true})
      this.props.loginAction(data)
      .then(resp=>{
        this.setState({btnVisible:false})
        if(resp == "success"){
          this.props.navigate("/")
        }else{
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          
          Toast.fire({
            icon: 'error',
            title: 'Email ve ya shifre yanlishdir'
          })
        }
      })
    }else{
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'error',
        title: 'Butun xanalar doldurulmalidir'
      })
    }
  }
  render() {
    const {login} = this.props;
    const {btnVisible} = this.state;
    return (
      <div className='flex items-center justify-center h-screen w-full'>
        <div className='w-1/3 bg-white p-3 rounded-xl shadow shadow-lg'>
            <h1 className='text-center text-2xl font-semibold'>Login</h1>
            <div className='flex flex-col gap-3'>
                <Input 
                    name="login.email"
                    type="email" 
                    title="Label"
                    value={login?.email}
                    labelClass="text-black text-sm"
                    className="bg-gray-100 p-2 rounded-xl text-sm"
                />
                <Input 
                    name="login.password"
                    type="password" 
                    title="Label"
                    value={login?.password}
                    labelClass="text-black text-sm"
                    className="bg-gray-100 p-2 rounded-xl text-sm"
                />
            </div>
            <div className='mt-3 justify-center flex'>
                <Button disabled={btnVisible} title="Login" onClick={this.loginBtn.bind(this, login)} className=" bg-blue-700" />
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  login: state.Data.login
});
const mapDispatchToProps = {loginAction}

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(LoginPage))
