import React, { Component } from 'react'
import Button from './Button'
import Swal from 'sweetalert2'
import { connect } from 'react-redux'
import { deleteData } from '../actions/MainAction'
import { Link } from 'react-router-dom'
export class Card extends Component {
  deleteItemBtn=(url)=>{
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.deleteData(url)
        .then(resp=>{
          this.props.getAfterChange()
          if(resp == "success"){
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }else{
            Swal.fire(
              'Error!',
              'Something went wronge',
              'error'
            )
          }
        })
        
      }
    })
  }
  render() {
    const {title= '', deleteBtn=true, editBtnData,titleHTML='', 
      editModalBtn=()=>{}, image="", url, linkUrl, linkPosition=false, 
      editAccess=true, statusView=false, status=0, editStatusBtn=()=>{}} = this.props;
    return (
      <div className='shadow shadow-gray-400 p-4 bg-white rounded-lg'>
        {
          image != ''?
          <img src={image} className='rounded-xl max-h-32 w-full' />:null
        }
        
        {
          title != ''?
          <h2 className='text-base'>Title: <span className='text-gray-500'>{title}</span></h2>:null
        }
        {
          titleHTML != ''?
          <h2 dangerouslySetInnerHTML={{ __html:titleHTML }} className='text-base'></h2>:null
        }
        <div className='mt-2 flex justify-between items-center'>
          {
            deleteBtn?
            <Button title="Delete" onClick={()=>this.deleteItemBtn(url)} className=" bg-red-700" />:null
          }
          {
            linkPosition?
            <Link className='bg-green-500 p-2 text-sm  rounded-xl text-white' to={linkUrl}>Info</Link>:null
          }
          {
            editAccess?
            <Button title="Edit" onClick={()=>editModalBtn(editBtnData)} className=" bg-blue-700" />:null
          }
          {
            statusView?
            <Button title="Show about" onClick={()=>editStatusBtn(editBtnData)} className={status==1?" bg-blue-700":"bg-gray-600"} />:null
          }
            
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(Card)
