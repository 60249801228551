import React, { Component } from 'react'
import Button from '../components/Button'
import { connect } from 'react-redux';
import { getTrainings } from '../actions/MainAction';
import Card from '../components/Card';
import { changeStateValue } from '../redux/MainReducer';
export class TrainingPage extends Component {
    componentDidMount(){
        this.props.getTrainings();
    }
    editModalBtn=(data='')=>{
        this.props.changeStateValue({
            name:'modalData',
            value: {
              position: true,
              id: data?.id?data?.id:'',
              title: data?.title?data?.title:'',
              path: data?.path?data?.path:'',
              description: data?.description?data?.description:'',
              image_full_url: data?.image_full_url?data?.image_full_url:'',
              price: data?.price?data?.price:'',
              duration: data?.duration?data?.duration:'',
              sub_title: data?.sub_title?data?.sub_title:'',
              syllabus_full_url: data?.syllabus_full_url?data?.syllabus_full_url:'',
              student_price: data?.student_price?data?.student_price:'',
              training_progress: data?.training_progress?data?.training_progress:'',
              unrequire: ["unrequire", "funcParams", "id", "image_full_url", "syllabus_full_url", "training_progress", "student_price", "price", "description", "syllabus"],
              afterCallFunction: this.props.getTrainings,
              funcParams:[],
              url: data?.id? `training/${data?.id}`: 'training',
              _method:  data?.id? 'put':'post'
            }
          })
    }
    getAfterChange=()=>{
        this.props.getTrainings();
    }
  render() {
    const {trainings} = this.props;
  
    return (
        <div className='container  ml-auto mr-auto mt-10'>
        <div className='flex justify-between items-center'>
            <h2 className='text-xl text-blue-700 font-semibold'>Training Page</h2>
            <Button title="Add Training" onClick={this.editModalBtn.bind(this)} className=" bg-blue-700"  />
        </div>
        <div className='grid grid-cols-4 gap-10 mt-10'>
           {
            trainings?.map((data, i)=>{
                return(
                    <Card
                        image={data?.image_full_url}
                        key={i}
                        title={data?.title}
                        editBtnData={data}
                        editModalBtn={this.editModalBtn}
                        url={`training/${data?.id}`}
                        linkPosition={true}
                        linkUrl={`/training/${data?.id}`}
                        getAfterChange={this.getAfterChange}
                    />
                )
            })
           }
           
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    trainings: state.Data.trainings
});
const mapDispatchToProps = {getTrainings, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(TrainingPage)

