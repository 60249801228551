import React, { Component } from 'react'
import Button from '../components/Button'
import { connect } from 'react-redux';
import { getStudentdReturns } from '../actions/MainAction';
import Card from '../components/Card';
import { changeStateValue } from '../redux/MainReducer';
export class StudentReturnPage extends Component {
    componentDidMount(){
        this.props.getStudentdReturns({type: 'single', id:this.props.trainingId});
    }
    editModalBtn=(data='')=>{
        this.props.changeStateValue({
            name:'modalData',
            value: {
              position: true,
              id: data?.id?data?.id:'',
              name: data?.name?data?.name:'',
              training_id: this.props.trainingId,
              image_full_url: data?.image_full_url?data?.image_full_url:'',
              text: data?.text?data?.text:'',
              raiting: data?.raiting?data?.raiting:'',
              position_status: data?.position?data?.position:'',
              unrequire: ["unrequire", "funcParams", "id", "image_full_url"],
              afterCallFunction: this.props.getStudentdReturns,
              funcParams:[{type: 'single', id:this.props.trainingId}],
              url: data?.id? `student-return/${data?.id}`: 'student-return',
              _method:  data?.id? 'put':'post'
            }
          })
    }
    getAfterChange=()=>{
        this.props.getStudentdReturns({type: 'single', id:this.props.trainingId});
    }
  render() {
    const {studentReturns} = this.props;
    return (
        <div className='container  ml-auto mr-auto mt-10'>
        <div className='flex justify-between items-center'>
            <h2 className='text-xl text-blue-700 font-semibold'>Student returns</h2>
            <Button title="Add Student return" onClick={this.editModalBtn.bind(this)} className=" bg-blue-700"  />
        </div>
        <div className='grid grid-cols-4 gap-10 mt-10'>
           {
            studentReturns?.map((data, i)=>{
                return(
                    <Card
                        key={i}
                        image={data?.image_full_url}
                        title={data?.name}
                        editBtnData={data}
                        editModalBtn={this.editModalBtn}
                        url={`student-return/${data?.id}`}
                        getAfterChange={this.getAfterChange}
                    />
                )
            })
           }
           
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentReturns: state.Data.studentReturns
});
const mapDispatchToProps = {getStudentdReturns, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(StudentReturnPage)







