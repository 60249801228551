import React, { Component } from 'react'
import Button from '../components/Button'
import { connect } from 'react-redux';
import { getTrainingQuestons } from '../actions/MainAction';
import Card from '../components/Card';
import { changeStateValue } from '../redux/MainReducer';
export class QuestionsPage extends Component {
    componentDidMount(){
        this.props.getTrainingQuestons(this.props.trainingId);
    }
    editModalBtn=(data='')=>{
        this.props.changeStateValue({
            name:'modalData',
            value: {
              position: true,
              id: data?.id?data?.id:'',
              question: data?.question?data?.question:'',
              answer: data?.answer?data?.answer:'',
              training_id: this.props.trainingId,
           
              unrequire: ["unrequire", "funcParams", "id", "image_full_url"],
              afterCallFunction: this.props.getTrainingQuestons,
              funcParams:[this.props.trainingId],
              url: data?.id? `training-questions/${data?.id}`: 'training-questions',
              _method:  data?.id? 'put':'post'
            }
          })
    }
    getAfterChange=()=>{
        this.props.getTrainingQuestons(this.props.trainingId);
    }
  render() {
    const {trainingQuestions} = this.props;
    return (
        <div className='container  ml-auto mr-auto mt-10'>
        <div className='flex justify-between items-center'>
            <h2 className='text-xl text-blue-700 font-semibold'>Training Questions</h2>
            <Button title="Add Question" onClick={this.editModalBtn.bind(this)} className=" bg-blue-700"  />
        </div>
        <div className='grid grid-cols-4 gap-10 mt-10'>
           {
            trainingQuestions?.map((data, i)=>{
                return(
                    <Card
                        key={i}
                        title={data?.question}
                        editBtnData={data}
                        editModalBtn={this.editModalBtn}
                        url={`training-questions/${data?.id}`}
                        getAfterChange={this.getAfterChange}
                    />
                )
            })
           }
           
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    trainingQuestions: state.Data.trainingQuestions
});
const mapDispatchToProps = {getTrainingQuestons, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(QuestionsPage)





